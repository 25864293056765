import React, { useRef, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { Input } from "../../../../components/NewInput";
import SquareButton from "../../../../components/SquareButton";
import SectionTitle from "../../../../components/SectionTitle";
import { BooleanCheckbox } from "../../../../components/NewInput";
import useAxios from "../../../../hooks/useAxios";
import { useUserContext } from "../../../../contexts/User";
import validationDictionary from "../../../../constants/validationDictionary";
import { focusElement, parseEmail } from "../../../../helpers";
import FieldsGrid from "../../components/FieldsGrid";
import { useSnackBarContext } from "../../../../contexts/SnackBar";
import AuthenticationParagraph from "../AuthenticationParagraph";

const SignUpForm = () => {
  const formRef = useRef(null);
  const { exec, loading } = useAxios();
  const history = useHistory();
  const { updateInkoins, setUserData } = useUserContext();
  const { showSnackBar } = useSnackBarContext();

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string()
          .min(3, validationDictionary.INVALID_NAME)
          .required(validationDictionary.INVALID_NAME),
        lastname: Yup.string()
          .min(3, validationDictionary.INVALID_LASTNAME)
          .required(validationDictionary.INVALID_LASTNAME),
        email: Yup.string()
          .email(validationDictionary.INVALID_EMAIL)
          .transform(parseEmail)
          .required(validationDictionary.INVALID_EMAIL)
          .test("email", validationDictionary.INVALID_EMAIL, (value) => {
            const accentPattern = /[À-ÖØ-öø-ÿçÇ]/;
            const specialCharPattern = /[!#$%^&*()+\-=\[\]{};':"\\|,<>\/?]+/;

            return parseEmail(value) !== false &&
              accentPattern.test(value) === false &&
              specialCharPattern.test(value) === false;
          }),
        confirm_email: Yup.string()
          .transform(parseEmail)
          .email(validationDictionary.INVALID_EMAIL)
          .oneOf([Yup.ref("email"), null], validationDictionary.EMAILS_DO_NOT_MATCH)
          .required(validationDictionary.INVALID_EMAIL),
        password: Yup.string()
          .min(8, validationDictionary.PASSWORD_MIN_CHARACTERS)
          .required(validationDictionary.INVALID_PASSWORD),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref("password"), null], validationDictionary.PASSWORDS_DO_NOT_MATCH)
          .required(validationDictionary.INVALID_PASSWORD),
        accepted_terms: Yup.bool()
          .oneOf([true], validationDictionary.TERMS_ARE_REQUIRED),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        method: "POST",
        url: "/user/register",
        data: { ...data, email: parseEmail(data.email) }
      });

      const { user, token, data: dataError, error, status } = response;

      if (error && status === 406) {
        showSnackBar({
          timeout: 5000,
          text: "Este e-mail já está cadastrado.",
          error
        });
        return;
      }

      if (token && user.id) {
        setUserData(user);

        if (user.type === 'PF') {
          updateInkoins(0);
          localStorage.setItem("@user_inkoins", JSON.stringify(0));
        }

        localStorage.setItem("@user_token", token);
        localStorage.setItem("@user_data", JSON.stringify(user));

        formRef.current.reset();

        history.push("/autenticacao/confirme-seu-email");

        return;
      }

      const errors = dataError.errors;
      const validationErrors = {};

      Object.keys(errors).forEach((err) => {
        validationErrors[err] = errors[err][0];
      });

      formRef.current.setErrors(validationErrors);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    focusElement('[data-testid="section-title"]');
  }, []);

  return (
    <Form ref={formRef} onSubmit={handleSubmit} id="contact-form">
      <SectionTitle
        small
        centered
        level="h2"
        children="Crie sua conta <b>de candidato</b>"
        dataTest="signup-title"
      />
      <AuthenticationParagraph>
        Se você for recrutador(a), {" "}
        <Link to="/para-empresas/autenticacao/cadastro/recrutador">
          Cadastre-se aqui
        </Link>.
      </AuthenticationParagraph>
      <FieldsGrid>
        <Input
          label="Nome"
          name="name"
          placeholder="Digite seu primeiro nome"
          type="text"
          data-test="signup-first-name"
        />
        <Input
          label="Sobrenome"
          name="lastname"
          placeholder="Digite seu sobrenome"
          type="text"
          data-test="signup-last-name"
        />
        <Input
          label="E-mail"
          name="email"
          placeholder="Digite seu e-mail"
          type="text"
          data-test="signup-email"
        />
        <Input
          label="Confirme o E-mail"
          name="confirm_email"
          placeholder="Confirme seu e-mail"
          type="text"
          data-test="confirm-email"
        />
        <Input
          label="Senha"
          name="password"
          placeholder="Digite sua senha"
          type="password"
          data-test="signup-password"
        />
        <Input
          label="Confirme a senha"
          name="password_confirmation"
          placeholder="Confirme sua senha"
          type="password"
          data-test="signup-password-confirmation"
        />
        <BooleanCheckbox name="accepted_terms" dataTest="accept-terms">
          Li e aceito a{" "}
          <Link target="_blank" to="/politica-de-privacidade">
            Política de Privacidade
            <br /> e Retenção de Dados Pessoais.
          </Link>
        </BooleanCheckbox>
      </FieldsGrid>
      <SquareButton
        disabled={loading}
        text="Criar conta"
        type="submit"
        dataTest="signup-submit"
      />
    </Form>
  );
};

export default SignUpForm;
