const races = [
  { value: 1, label: "Outro" },
  { value: 5, label: "Amarela" },
  { value: 2, label: "Branca" },
  { value: 6, label: "Indígena" },
  { value: 3, label: "Preta" },
  { value: 4, label: "Parda" },
  { value: 7, label: "Prefiro não informar" },
];

export default races;
