import React, { useRef } from "react";
import { string, func } from "prop-types";

import styles from "./styles.module.scss";

const Input = ({
  name,
  label,
  placeholder,
  onChange,
  value,
}) => {
  const fieldRef = useRef();

  return (
    <div className={styles.wrapper}>
      <label htmlFor={`${name}-field`} className={styles.label}>
        {label}
      </label>
      <input
        type="text"
        onChange={onChange}
        className={[styles.input, value && styles.filled].join(" ")}
        id={`${name}-field`}
        data-testid={`${name}-field`}
        placeholder={placeholder}
        ref={fieldRef}
        value={value}
        autoComplete="off"
      />
    </div>
  );
};

Input.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  placeholder: string.isRequired,
  onChange: func.isRequired,
  value: string.isRequired
};

Input.defaultProps = {
  disabled: false,
};

export default Input;
