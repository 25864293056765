import React, { useRef, useEffect, useState } from "react";
import { useField } from "@unform/core";
import { string, func, bool } from "prop-types";

import styles from "./styles.module.scss";

const Radio = ({ name, options, onChange, displayError, ...rest }) => {
  const inputRef = useRef([]);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);
  const currentChecked = options.find((option) => option.checked);
  const [currentValue, setCurrentValue] = useState(defaultValue ?? currentChecked?.id);

  const parseNewValue = (value) => {
    if (value === "true") {
      return true;
    }

    if (value === "false") {
      return false;
    }

    return value;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      path: "value",
      ref: inputRef.current,
      getValue(refs) {
        const checked = refs.find((ref) => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find((ref) => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <fieldset className={[styles.wrapper, styles["is-radio"]].join(" ")}>
      {displayError && error && (
        <span
          className={[styles.error, styles.isRadioError].join(" ")}
          id={`error_${name}`}
          data-testid={`error-message-${name}`}
        >
          {error}
        </span>
      )}
      <div className={styles.radioList}>
        {options.map((option, index) => (
          <div key={`field-${name}-${option.id}`}>
            <input
              ref={(elRef) => (inputRef.current[index] = elRef)}
              type="radio"
              name={fieldName}
              value={option.id}
              defaultChecked={currentValue === option.id}
              id={`field-${name}-${option.id}`}
              data-testid={`field-${name}-${option.id}`}
              className={styles.radio}
              onChange={(e) => {
                clearError();

                const newValue = parseNewValue(e.target.value);

                setCurrentValue(newValue);
                onChange(newValue);
              }}
              {...rest}
            />
            <label
              htmlFor={`field-${name}-${option.id}`}
              className={[
                styles.label,
                styles.forRadio,
                currentValue === option.id && styles.active,
              ].join(" ")}
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

Radio.propTypes = {
  name: string.isRequired,
  onChange: func,
  displayError: bool,
};

Radio.defaultProps = {
  onChange: () => {},
  displayError: false,
};

export default Radio;
