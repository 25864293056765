import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import SquareButton from "../../../../components/SquareButton";
import { focusElement } from "../../../../helpers";

import { SectionTitle } from "../../../Account/components/SectionTitle";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

// eslint-disable-next-line no-unused-vars
import styles from "./styles.module.scss";
import { isUserFullySignedUp } from "../../../Account/hooks/isUserFullySignedUp";

const CandidateWelcome = () => {
  const history = useHistory();
  const { handleUserAccessPage } = isUserFullySignedUp();

  const nextStep = () => {
    history.push("/autenticacao/cadastro/candidato/dados-pessoais");
  };

  useEffect(() => {
    focusElement('[data-testid="account-section-title"]');
    handleUserAccessPage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AuthenticationWrapper>
        <SectionTitle centered>
          Seja Bem-Vindo(a)
          <br />
          <b>à Inklua!</b>
        </SectionTitle>
        <p className={styles.paragraph} tabIndex="0">
          Preencha o seu perfil, assim nossos recrutadores conseguem encontrar
          uma vaga perfeita para você! E se é o seu primeiro emprego, sabia que
          somos muito bons em inserir pessoas no mercado de trabalho.
        </p>
        <SquareButton text="Começar" onClick={nextStep} />
      </AuthenticationWrapper>
    </>
  );
};

export { CandidateWelcome };
