import React, { useEffect } from "react";

import ResendEmail from "../../components/ResendEmail";
import AuthenticationWrapper from "../../components/AuthenticationWrapper";
import { isUserFullySignedUp } from "../../../Account/hooks/isUserFullySignedUp";

const ValidateYourEmail = () => {
  const { handleUserAccessPage } = isUserFullySignedUp();

  useEffect(() => {
    handleUserAccessPage();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <AuthenticationWrapper>
      <ResendEmail />
    </AuthenticationWrapper>
  );
};

export default ValidateYourEmail;
