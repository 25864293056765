import React, { useEffect } from "react";
import SectionTitle from "../../../../components/SectionTitle";

import SquareLinkButton from "../../../../components/SquareLinkButton";
import { focusElement } from "../../../../helpers";

import AuthenticationParagraph from "../../components/AuthenticationParagraph";

const EmailValidatedSuccesfully = () => {
  useEffect(() => {
    focusElement('[data-testid="section-title"]');
  }, []);

  return (
    <>
      <SectionTitle
        small
        centered
        level="h2"
        children="Seu e-mail <b>já foi validado</b>"
      />
      <AuthenticationParagraph>
        Agora é só você terminar de preencher o seu perfil
      </AuthenticationParagraph>
      <SquareLinkButton
        url="/autenticacao/cadastro/candidato/bem-vindo"
        type="fill"
        text="Próxima etapa"
      />
    </>
  );
};

export default EmailValidatedSuccesfully;
