const genders = [
  { value: 1, label: "Outro" },
  { value: 3, label: "Feminino" },
  { value: 5, label: "Gênero neutro" },
  { value: 2, label: "Masculino" },
  { value: 4, label: "Transgênero" },
  { value: 6, label: "Prefiro não informar" },
];

export default genders;
