import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { SectionTitle } from "../../../Account/components/SectionTitle";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";
import { focusElement } from "../../../../helpers";

import SquareButton from "../../../../components/SquareButton";

import Illustration from "./assets/illustration.svg";

import styles from "./styles.module.scss";

const CandidateFullySignedUp = () => {
  const history = useHistory();

  const nextStep = () => {
    history.push("/#vagas");
  };

  useEffect(() => {
    focusElement('[data-testid="onboarding-complete-image"]');
  }, []);

  return (
    <>
      <AuthenticationWrapper>
        <img
          tabIndex="0"
          src={Illustration}
          data-testid="onboarding-complete-image"
          alt="Ilustração de um grupo de pessoas com deficiência juntas. Da esquerda para a direita tem um rapaz com deficiência auditiva, de pele clara, cabelos castanhos, usa uma camiseta branca de manga curta e calça verde claro, com a mão esquerda ele está fazendo o sinal de positivo e com a mão direita está apoiada no ombro de um cadeirante. Este cadeirante é um rapaz mulato, de cabelos castanhos, também usa uma camiseta branca e calça verde claro. Ao lado deste cadeirante tem uma mulher que possui deficiência visual, ela tem pele branca, cabelos loiros de comprimento médio, usa um óculo escuro e um vestido azul escuro, com a mão direita ela segura uma bengala guia e com a mão esquerda ele abraça outra mulher. Esta última mulher é deficiente física, usa uma prótese no braço esquerdo, ela é mulata e tem cabelos azuis, usa uma camiseta branca e calça verde claro, com a mão direita ela apoia no ombro da outra mulher descrita anteriormente. Todos estão felizes e sorrindo!"
          className={styles.image}
        />
        <SectionTitle centered>
          Cadastro finalizado
          <br />
          <b>com sucesso!</b>
        </SectionTitle>
        <p className={styles.paragraph} tabIndex="0">
          Agora você pode pesquisar em nosso site as vagas de seu interesse,
          caso seu perfil seja compatível com a vaga, um de nossos recrutadores
          irá entrar em contato com você. Boa sorte!
        </p>
        <SquareButton text="Pesquisar vagas" onClick={nextStep} />
      </AuthenticationWrapper>
    </>
  );
};

export { CandidateFullySignedUp };
