import React from "react";
import { string, bool } from "prop-types";

const Info = ({ color, hidden }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      aria-hidden={hidden}
    >
      <g
        fill={color}
        stroke={color}
        strokeWidth=".5"
        transform="translate(1 1)"
      >
        <path d="M7 0a7 7 0 1 0 0 14A7 7 0 0 0 7 0Zm0 12.6A5.6 5.6 0 1 1 7 1.4a5.6 5.6 0 0 1 0 11.2Z" />
        <circle cx="7" cy="4.2" r="1" />
        <path d="M7 5.6a.7.7 0 0 0-.7.7v3.5a.7.7 0 0 0 1.4 0V6.3a.7.7 0 0 0-.7-.7Z" />
      </g>
    </svg>
  );
};

Info.propTypes = {
  color: string,
  hidden: bool,
};

Info.defaultProps = {
  color: "#1B9B9E",
  hidden: true,
};

export default Info;
