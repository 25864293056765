import React, { useEffect, useRef } from "react";
import { bool, string, node, func } from "prop-types";

import { Plus } from "../../libs/icons";

import styles from "./styles.module.scss";

const Modal = ({ title, children, visible, handleClose, showCloseButton = true, className }) => {
  const modalRef = useRef();

  useEffect(() => {
    if (visible) {
      // console.log('is visible', modalRef.current)
      modalRef.current.focus();
    }
  }, [visible]);

  return (
    <div className={[styles.wrapper, visible && styles.visible].join(" ")}>
      <div className={[styles.container, className].join(" ")} ref={modalRef}>
        {title && showCloseButton && (
          <div className={styles.header}>
            <span className={styles.title}>{title}</span>
            {showCloseButton && (
              <button
                onClick={handleClose}
                aria-label="Fechar Modal"
                className={styles.closeButton}
              >
                <Plus color="#192B4B" />
              </button>
            )}
          </div>
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: string,
  children: node.isRequired,
  visible: bool.isRequired,
  handleClose: func.isRequired,
};

export { Modal };
