import React from "react";

import styles from "./styles.module.scss";

const PrivacyPolicyContent = () => (
  <main className={styles.wrapper}>
    <small className={styles.preffix}>
      Política de Privacidade inicia vigência em 10 de janeiro de 2023
    </small>
    <h1>Política de privacidade para Usuário</h1>

    <p>Para consultar o Aviso Legal para Empresas e recrutadores <a href="https://inklua.com/para-empresas/politica-de-privacidade">clique aqui</a>.</p>

    <p>Como condição de acesso e uso do site, o Usuário declara que fez a leitura completa e atenta desta Política, tomando plena ciência dela, conferindo sua livre e expressa concordância com os termos aqui estipulados, incluindo a coleta dos Dados aqui mencionados, bem como sua utilização para os fins especificados nesta Política.</p>

    <p>Caso o Usuário não esteja de acordo com as disposições desta Política, deverá descontinuar seus acessos ou usos do site.</p>

    <h2>1. Responsável pelos dados</h2>

    <p>Razão Social: INKLUA SERVICOS PARA INCLUSAO DE PCD LTDA</p>

    <p>CNPJ: 22.692.860/0001-76</p>

    <p>Endereço: Al Rio Negro, 503, Sala 2020, Alphaville, Barueri - SP</p>
    <br />

    <h2>2. Finalidade e uso dos dados pessoais</h2>

    <p>A Inklua é uma plataforma online para inclusão de pessoas com deficiência no mercado de trabalho. As pessoas, Usuários, utilizam os nossos Serviços para serem encontradas, para encontrarem oportunidades profissionais e encontrarem informações. Nosso legítimo interesse é aproximar pessoas e empresas que utilizam nossa plataforma e serviços, além disso, exibir anúncios, como anúncios de vagas de emprego, que achamos que serão do seu interesse. Os dados dos Usuários são disponibilizados para as empresas apenas mediante contrato e para finalidade de recrutamento e seleção para vagas de trabalho.</p>

    <p>De uma forma mais detalhada, utilizamos as informações e os seus dados pessoais coletados para seguintes finalidades:</p>
    <ol>
      <li>Viabilizar a participação em processos seletivos de trabalho;</li>
      <li>Incluir seus dados no banco de talentos da Inklua e das empresas em cujos processos seletivos o Usuário demonstrou interesse;</li>
      <li>Entrar em contato com o Usuário, em razão de andamento do processo seletivo de que o Usuário esteja participando ou para informá-lo sobre novos processos que possam ser de seu interesse, incluindo o envio de alertas de vagas;</li>
      <li>Permitir que empresas e recrutadores busquem ativamente candidatos (Usuários) que sejam aderentes às vagas abertas dentro do banco de dados da Inklua;</li>
      <li>Enviar informativos, comunicados institucionais e newsletter, inclusive sobre produtos e serviços oferecidos pela Inklua e seus parceiros.</li>
    </ol>

    <p>Poderemos utilizar seus dados em conexão com nossos serviços para outras finalidades lícitas e mediante sua autorização prévia ou para cumprir obrigações acessórias ao que já nos comprometemos com o Usuário. Ainda, cederemos os dados a organismos e autoridades públicas, sempre e quando sejam requeridos de conformidade com as disposições legais e regulamentares.</p>

    <br />

    <h2>3. Dados pessoais que coletamos e que formam o currículo dos usuários</h2>

    <p>Durante a utilização, a Inklua poderá coletar os dados do Usuário quando este os submeter em seu cadastro ou quando interagir nas formas acima mencionadas. Esse conjunto de dados que compõem o currículo pode conter os seguintes tipos de dados pessoais: nome, CPF, RG, e-mail particular, data de nascimento, celular particular, telefone fixo particular, salário, currículo, estado, cidade, bairro, link de portfólio, disponibilidade, profissão, histórico profissional, escolaridade e formação acadêmica.</p>

    <p>A Inklua, no contexto de recrutamento e seleção, permite que o Usuário insira informações e dados pessoais considerados sensíveis, tais como: laudos médicos, gênero, cor/raça, relatórios sobre acessibilidade para processos seletivos de pessoas com deficiência (PcD), informações para participação em processos seletivos de vagas inclusivas, dentre outros, conforme determinado em Lei e previsto no artigo 5º, II da LGPD.</p>

    <p>Ao realizar o cadastro, é importante que o Usuário, ou a pessoa autorizada por ele para preencher seus dados pessoais, insira apenas informações verdadeiras e atualizadas, já que não nos responsabilizamos pela verificação. A responsabilidade, em qualquer caso, civil e/ou criminal, é do Usuário quanto à veracidade, exatidão e autenticidade das informações incluídas em nosso cadastro.</p>

    <p>A inserção de todos os dados pessoais não é obrigatória e será sempre facultativa ao Usuário, no entanto, nesse caso, a utilização e efetividade dos nossos Serviços será limitada. Ao realizar o cadastro o Usuário autoriza expressamente o seu compartilhamento com as empresas para processos de seleção e admissão.</p>

    <p>A Inklua se compromete a não utilizar tais informações para outras finalidades, bem como envidará seus melhores esforços para garantir que seus clientes as utilizem somente dentro das finalidades previstas no ordenamento vigente, incluindo a legislação trabalhista. No entanto, a decisão final de contratação cabe à empresa anunciante.</p>

    <p>A Inklua não coleta dados pessoais nem permite a utilização dos serviços por menores de 15 anos. Para acessar ou utilizar o nosso site é necessário que o Usuário tenha mais de 18 anos de idade. Se o Usuário tiver mais de 15 anos, mas for menor de 18 anos, a utilização do nosso site somente pode ocorrer sob a supervisão dos seus pais ou tutores. Se a Inklua tiver conhecimento de que um menor nos forneceu informações sem a autorização dos pais ou tutores, a Inklua eliminará tais informações e removerá a conta do menor.</p>

    <br />

    <h2>4. Dados pessoais necessários para identificar os usuários corporativos (Empresas e recrutadores)</h2>

    <p>A Inklua também poderá armazenar o nome, e-mail, telefone de contato e documentos de identificação para permitir e vincular o seu acesso aos serviços prestados pela Inklua aos seus clientes corporativos: empresas, recrutadores e novos controladores.</p>

    <p>Neste caso seus dados de Usuário corporativo serão utilizados somente para garantir uma navegação segura e o acesso correto aos produtos e serviços contratados, sem compartilhamento com outros controladores.</p>

    <br />

    <h2>5. Do tratamento de dados pessoais e compartilhamento com novos controladores</h2>

    <p>A Inklua será a controladora dos dados pessoais dos Usuários, quando inseridos através do site www.inklua.com ou captados por um de seus sistemas. Nesses casos, é nossa responsabilidade eleger adequadamente as bases legais condizentes com as finalidades previstas na presente política, bem como atender de forma direta às suas solicitações quanto aos direitos previstos na LGPD. Apenas coletamos e processamos dados pessoais sobre o Usuário onde temos bases legais para isso. Bases legais incluem consentimento (sempre que o Usuário tiver consentido) e o legítimo interesse.</p>

    <p>Os dados dos Usuários poderão ser compartilhados com novos controladores e empresas autorizadas pela Inklua a utilizarem seus sistemas ou acessarem seus bancos de dados, sempre e especificamente no contexto de recrutamento e seleção ou para comunicação, somente pela Inklua, de oferta de produtos e serviços que possam ser de interesse ou para os Usuários.</p>

    <p>Os novos controladores, tais como empresas que anunciam vagas de emprego, consultorias de recrutamento, bem como empresas que queiram realizar triagens no banco de candidatos (Usuários) da Inklua, poderão importar ou obter seus dados, tendo autonomia para se comunicarem diretamente com Usuários para a condução do processo seletivo ou para convidá-los a se inscrever em outras vagas e para temas relacionados. O resultado final para contratação é de responsabilidade das empresas autorizadas. A Inklua não tem ingerência sobre a condução e decisão dos processos seletivos ou sobre como novos controladores de dados utilizarão referidas informações para além de seus sistemas e plataformas.</p>

    <p>Nós também podemos compartilhar os dados pessoais de Usuários com outras empresas que contribuem para melhorar ou facilitar as operações através do site, como por exemplo: ferramentas de gerenciamento de bancos de dados, gerenciamento de e-mails, fluxos internos e de atendimento aos Usuários, dentre outros. A Inklua se compromete a contratar apenas fornecedores que cumpram a legislação protetiva de dados, mediante a assinatura de acordos ou convênios com cláusulas específicas para isso.</p>

    <p>Em casos em que haja a necessidade de transferência internacional de dados pessoais para outros países, a Inklua se compromete a contratar apenas fornecedores e clientes que adotem medidas de segurança e boas práticas compatíveis com o nível de proteção estabelecido pela legislação brasileira, conforme regulamentação pela Autoridade Nacional de Dados Pessoais (ANPD). Na falta de regulamentação específica, nos comprometemos a trabalhar somente com empresas aderentes às demais normas de proteção de dados, como a GDPR europeia ou CCPA americana; ou com empresas cujos contratos contenham cláusulas específicas para as transferências com a Inklua; bem como a restringir a transferência apenas ao estritamente necessário para cumprimento das obrigações assumidas com o Usuário.</p>

    <br />

    <h2>6. Proteção do nosso sistema e dos dados pessoais</h2>

    <p>Para proteger os dados pessoais coletados e garantir a segurança dos nossos sistemas, utilizamos tecnologias e procedimentos adequados de acordo com o nível de risco e o serviço fornecido, possuindo uma equipe responsável por gerenciá-los em conformidade com as previsões legais, requisitos regulatórios, mudanças de tecnologia, dentre outros fatores relevantes que possam influenciar a proteção de dados</p>

    <p>Em razão da própria natureza da Internet, existe o risco de que terceiros mal-intencionados acessem indevidamente as informações armazenadas nos nossos sistemas. Caso isso ocorra, nós nos responsabilizaremos nos limites previstos na legislação vigente aplicável.</p>

    <p>É proibido o uso de qualquer dispositivo, software ou outro recurso que venha a interferir nas atividades e operações da Inklua, tanto pelo site quanto por outros sistemas ou bancos de dados. Caso seja identificada qualquer intromissão, tentativa, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as disposições estipuladas nesta Política e/ou leis vigentes aplicáveis, aí incluída a LGPD, o responsável estará sujeito às sanções aplicáveis, estejam elas em lei ou previstas neste documento. O responsável deverá, também, indenizar por eventuais danos causados.</p>

    <br />

    <h2>7. Retenção de dados pessoais</h2>

    <p>Manteremos os Dados Pessoais de Usuários durante o tempo que a sua conta em nosso site permanecer ativa ou enquanto for necessário fornecer-lhes nossos Serviços, enquanto o titular não tenha solicitado a eliminação de sua conta ou dos Dados Pessoais; bem como durante o tempo necessário para cumprir as obrigações legais. No entanto, podemos reter certas informações conforme exigido para atender alguma legislação ou regulamento aplicável ou enquanto necessário para atender nossos legítimos interesses quando a Inklua for controladora do tratamento dos dados pessoais. Quando não tivermos bases legais para continuar processando suas informações pessoais, elas serão excluídas ou anonimizadas de acordo com as leis vigentes.</p>

    <br />

    <h2>8. Direitos previstos pela LGPD</h2>

    <p>Em relação aos Dados Pessoais que a Inklua armazene e realize o tratamento com a prestação de seus Serviços, o Usuário tem os seguintes direitos:</p>
    <ol>
      <li>Retirar ou negar o consentimento quando desejar e em casos que contarmos com legítimo interesse, o Usuário tem o direito de se negar;</li>
      <li>Receber confirmação da existência de tratamento;</li>
      <li>Acessar ou coletar seus dados;</li>
      <li>Retificar ou modificar os dados incompletos, inexatos ou desatualizados;</li>
      <li>Rejeitar, limitar, se opor ou restringir o uso de dados;</li>
      <li>Excluir seus dados: anonimização, bloqueio ou eliminação de dados desnecessários, excessivos;</li>
      <li>Pedido de portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa.</li>
    </ol>

    <p>Deve-se levar em conta que os direitos acima mencionados estão sujeitos a certas limitações, conforme estabelecido pela legislação aplicável. Os pedidos individuais serão tratados dentro do prazo estipulado pelos regulamentos relevantes, e começa a partir do momento em que a Inklua confirmar a recepção do pedido do Usuário.</p>

    <p>Caso o Usuário solicite à Inklua informações sobre seus Dados Pessoais ou solicite o exercício dos direitos indicados na legislação aplicável, a Inklua poderá, antes de responder a qualquer solicitação, solicitar que o Usuário forneça algumas informações para confirmar sua identidade, inclusive para fins de segurança e validação da solicitação.</p>

    <p>Em relação aos Dados Pessoais fornecidos diretamente a empresas clientes e seus prepostos ou recrutadores, o Usuário deve exercer seus direitos contra eles.</p>

    <br />

    <h2>9. Do encarregado pelo tratamento de dados pessoais</h2>

    <p>Caso o Usuário tenha alguma questão específica que não tenha sido esclarecida por esta Política de Privacidade, o Usuário poderá, como último recurso, entrar em contato com nosso encarregado de dados (DPO) e enviar suas dúvidas e solicitações para o e-mail: <a href="mailto:dpo@inklua.com">dpo@inklua.com</a>.</p>

    <br />

    <h2>10. Disposições gerais</h2>

    <p>No momento do cadastro, o Usuário deverá ler, compreender e aceitar esta Política, conforme opção específica disponibilizada no formulário. Porém, esta Política tem natureza de contrato de adesão e passa por revisões periódicas, sem que seja necessária a sua notificação prévia. Por isso, é importante que o Usuário consulte o documento para saber se continua concordando com seus termos antes de seguir com a navegação.</p>

    <p>Ao concordar com os presentes termos, o usuário declara ter ciência e pleno entendimento de que a decisão final sobre a participação ou não em um processo seletivo é exclusivamente reservada à Inklua e à empresa contratante. Em nenhuma circunstância, a decisão de não participação será considerada como uma perda de oportunidade por parte do usuário, cabendo à Inklua e à empresa contratante a total discricionariedade sobre a seleção e convocação de candidatos.</p>

    <p>Esta Política de Privacidade está em conformidade e deverá ser interpretada com base na legislação brasileira. Para dirimir eventuais dúvidas ou questões relativas a ela, as partes elegem o Foro da Comarca de São Paulo/SP, com exclusão de qualquer outro.</p>
  </main>
);

export default PrivacyPolicyContent;
