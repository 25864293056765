import React, { useEffect, useState, useRef } from "react";
import { string, arrayOf, func, bool } from "prop-types";
import { useField } from "@unform/core";

// eslint-disable-next-line no-unused-vars
import AutoCompleteList from "../AutoCompleteList";

import { matchOptions } from "./utils";
import styles from "./styles.module.scss";

const AutoCompleteInput = ({
  name,
  label,
  placeholder,
  options,
  disabled,
  fetch,
  setter,
  defaultValue,
  ariaLabel,
  minimumCharacters
}) => {
  const fieldRef = useRef();
  const [suggestionsVisibility, setSuggestionsVisibility] = useState(true);
  const [currentValue, setCurrentValue] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const { fieldName, registerField, error, clearError } = useField(name);

  const handleOnChange = (event) => {
    const { value } = event.target;

    if (minimumCharacters &&
      value.length < minimumCharacters &&
      value.length > currentValue) return;

    fetch(value);
    setCurrentValue(value);
    setter(value);
  };

  const handleClick = (value) => {
    fieldRef.current.value = value;
    setSuggestionsVisibility(false);
    setCurrentValue(value);
    setter(value);
  };

  const handleOnFocus = () => {
    clearError();
    setSuggestionsVisibility(true);
  };

  const handleOnBlur = () => {
    setTimeout(() => {
      setSuggestionsVisibility(false);
    }, 100);
  };

  useEffect(() => {
    const filteredSuggestions = currentValue
      ? matchOptions(options, currentValue)
      : [];

    if (currentValue && filteredSuggestions[0]) {
      if (filteredSuggestions[0].toUpperCase() === currentValue.toUpperCase()) return handleClick(filteredSuggestions[0]);
    }

    setSuggestions(filteredSuggestions);
    setSuggestionsVisibility(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  useEffect(() => {
    const filteredOptions = options.filter((item) =>
      item.includes(fieldRef.current.value)
    );

    if (!filteredOptions.length) {
      setSuggestions([]);

      if (!defaultValue) {
        fieldRef.current.value = "";
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: fieldRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div className={styles.wrapper}>
      <label htmlFor={`${name}-field`} className={styles.label}>
        {label}
      </label>
      {error && (
        <span
          className={styles.error}
          id={`error_${name}`}
          data-testid={`error-message-${name}`}
        >
          {error}
        </span>
      )}
      <input
        type="text"
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        className={[
          styles.input,
          currentValue && styles.filled,
          error ? styles.invalid : "",
        ].join(" ")}
        id={`${name}-field`}
        data-testid={`${name}-field`}
        placeholder={placeholder}
        ref={fieldRef}
        disabled={disabled}
        defaultValue={defaultValue}
        aria-label={ariaLabel}
        aria-invalid={Boolean(error)}
        aria-describedby={`error_${name}`}
        autoComplete="off"
      />
      <AutoCompleteList
        suggestions={suggestions}
        handleClick={handleClick}
        filter={currentValue}
        visible={Boolean(suggestionsVisibility && currentValue)}
      />
    </div>
  );
};

AutoCompleteInput.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  placeholder: string.isRequired,
  options: arrayOf(string).isRequired,
  fetch: func.isRequired,
  setter: func.isRequired,
  disabled: bool,
  ariaLabel: string,
  minimumCharacters: bool
};

AutoCompleteInput.defaultProps = {
  disabled: false,
  ariaLabel: "",
  minimumCharacters: false
};

export default AutoCompleteInput;
