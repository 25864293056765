import { useHistory } from "react-router-dom";

const STATUSES = [
  {
    slug: "pending-personal-data",
    path: "/autenticacao/cadastro/candidato/dados-pessoais",
  },
  {
    slug: "pending-email-validation",
    path: "/autenticacao/confirme-seu-email",
  },
  {
    slug: "pending-disabilities",
    path: "/autenticacao/cadastro/candidato/deficiencia",
  },
  {
    slug: "pending-availability",
    path: "/autenticacao/cadastro/candidato/disponibilidade",
  },
  {
    slug: "pending-work-experiences",
    path: "/autenticacao/cadastro/candidato/experiencia",
  },
  {
    slug: "pending-degrees",
    path: "/autenticacao/cadastro/candidato/formacao-academica",
  },
  {
    slug: "pending-languages",
    path: "/autenticacao/cadastro/candidato/idiomas",
  },
  // Caso a tela de indicação retorne, descomentar as linhas abaixo
  // {
  //   slug: "pending-indication",
  //   path: "/autenticacao/cadastro/candidato/indicacao",
  // },
  {
    slug: "complete",
    path: "/",
  },
];

export const isUserFullySignedUp = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();

  const updateUserStatus = ({ status = "complete" }) => {
    let payload = status;
    const userData = JSON.parse(localStorage.getItem("@user_data"));

    if (!status || typeof status !== "string") {
      payload = "complete";
    }

    localStorage.setItem(
      "@user_data",
      JSON.stringify({
        ...userData,
        user_signup_status: payload,
      })
    );
  };

  const getUserData = () => {
    return JSON.parse(localStorage.getItem("@user_data")) ?? {};
  };

  const checkUserStatusAndRedirect = () => {
    const userData = getUserData();
    const { status: userStatus, user_signup_status, type } = userData;

    if (userStatus === "complete") return;

    if (type === "PJ" && userStatus !== "pending-email-validation") {
      return;
    }

    if (type === "PJ" && userStatus === "pending-email-validation") {
      history.push("/autenticacao/confirme-seu-email");
      return;
    }

    STATUSES.forEach((status) => {
      if (userStatus === status.slug || user_signup_status === status.slug) {
        history.push(status.path);
        return;
      }
    });
  };

  const handleUserAccessPage = () => {
    const { status: userStatus, user_signup_status } = getUserData();

    if (userStatus === "complete") {
      history.push("/autenticacao/cadastro/candidato/cadastro-completo");
      return;
    }

    STATUSES.forEach((status) => {
      if (userStatus === status.slug || user_signup_status === status.slug) {
        history.push(status.path);
        return;
      }
    });

    return;
  };

  return { updateUserStatus, checkUserStatusAndRedirect, handleUserAccessPage };
};
