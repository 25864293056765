import useAxios from "../hooks/useAxios";

const BRAZIL_STATES_FILE = "states-brazil.json";
const BRAZIL_CITIES_FILE = "cities-brazil.json";

export const useStatesAndCities = () => {
  const { exec, loading } = useAxios();

  const getCities = async (stateID) => {
    const response = await exec({
      method: "GET",
      // url: `/institucional/static-assets/${BRAZIL_CITIES_FILE}`,
      url: `/static-assets/${BRAZIL_CITIES_FILE}`,
      baseUrl: window.location.origin,
    });
    const filteredResponse = response.filter(
      (state) => state.UF === Number(stateID) || state.UF === stateID
    );

    return filteredResponse;
  };

  const getStates = async () => {
    const response = await exec({
      method: "GET",
      url: `/static-assets/${BRAZIL_STATES_FILE}`,
      // url: `/institucional/static-assets/${BRAZIL_STATES_FILE}`,
      baseUrl: window.location.origin,
    });

    return response;
  };

  return { loading, getCities, getStates };
};
