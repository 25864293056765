import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import SquareButton from "../../../../components/SquareButton";
import useAxios from "../../../../hooks/useAxios";
import { focusElement } from "../../../../helpers";

import { SectionTitle } from "../../../Account/components/SectionTitle";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import styles from "./styles.module.scss";

  // Caso a tela de indicação retorne, descomentar a linha abaixo
// import { isUserFullySignedUp } from "../../../Account/hooks/isUserFullySignedUp";
import { useSnackBarContext } from "../../../../contexts/SnackBar";

const CandidateLanguages = () => {
  const history = useHistory();
  const { exec, loading } = useAxios();
  // Caso a tela de indicação retorne, descomentar a linha abaixo
  // const { handleUserAccessPage } = isUserFullySignedUp();
  const { showSnackBar } = useSnackBarContext();

  const handleAddEnglishLevel = () => {
    history.push("/autenticacao/cadastro/candidato/idiomas/adicionar");
  };

  const handleLogout = () => {
    localStorage.removeItem("@user_data");
    localStorage.removeItem("@user_token");
    localStorage.removeItem("@user_inkoins");
    localStorage.removeItem("@user_shares");
    localStorage.removeItem("@user_redirect");

    history.push("/");
  };

  // Caso a tela de indicação retorne, descomentar a linha abaixo
  // const goToNextStep = () => {
  //   history.push("/autenticacao/cadastro/candidato/indicacao");
  // };

  const skipStepAndProceed = async () => {
    const response = await exec({
      useCredentials: true,
      method: "PUT",
      url: "/candidate/languages",
      data: {
        no_language: true,
      },
    });

    if (response.error) {
      showSnackBar({
        timeout: 5000,
        text: response.error,
        error: true
      });
      handleLogout();
      return;
    }

    if(response.user.status) {
      const userData = JSON.parse(localStorage.getItem("@user_data"));
      const url = userData.has_disability ? "/autenticacao/cadastro/candidato/cadastro-completo-pcd" : "/autenticacao/cadastro/candidato/cadastro-completo";
      history.push(url);
      localStorage.setItem("@user_data", JSON.stringify({ ...userData, status: "complete" }));
      return;

      // Caso a tela de indicação retorne, descomentar a linha abaixo e remover as linhas acima
      // localStorage.setItem("@user_data", JSON.stringify({...response.user, status: "pending-indication"}));
      // handleUserAccessPage();
      // goToNextStep();
    }
  };

  useEffect(() => {
    focusElement('[data-testid="account-section-title"]');
  }, []);

  return (
    <AuthenticationWrapper>
      <SectionTitle>Nível de inglês</SectionTitle>
      <div className={styles.form}>
        <p tabIndex="0">
          Adicione seu nível de inglês. Se não possui, não tem problema, na
          Inklua tem oportunidades para todo mundo ;)
        </p>
        <SquareButton
          text="Cadastrar nível de inglês"
          onClick={handleAddEnglishLevel}
        />
        <SquareButton
          disabled={loading}
          simple
          text="Preencher depois"
          onClick={skipStepAndProceed}
        />
      </div>
    </AuthenticationWrapper>
  );
};

export { CandidateLanguages };
