import { node } from "prop-types";

import styles from "./styles.module.scss";

const Container = ({ children }) => {
  return <section className={styles.wrapper}>{children}</section>;
};

Container.propTypes = {
  children: node.isRequired,
};

export { Container };
