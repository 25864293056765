import { string } from "prop-types";

import defaultImage from "./default-avatar.png";
import useEnterPriseEnvCheck from "../../hooks/useEnterpriseEnvCheck";
import styles from "./styles.module.scss";

const UserInformationWithAvatar = ({ primaryText, secondaryText }) => {
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  return (
    <div className={styles.wrapper}>
      <img
        className={styles.avatar}
        src={isEnterpriseEnv ? 'https://s3.sa-east-1.amazonaws.com/public.inklua/para-empresas/Busca/profile.png' : defaultImage}
        alt={`Imagem de ${primaryText}`}
      />
      <div>
        {secondaryText && <span className={styles.message}>{secondaryText}</span>}
        <span className={styles.name}>{primaryText}</span>
      </div>
    </div>
  )
};

UserInformationWithAvatar.propTypes = {
  primaryText: string.isRequired,
  secondaryText: string,
};

UserInformationWithAvatar.defaultProps = {
  secondaryText: null,
};

export { UserInformationWithAvatar };
